*{
  margin: 0;
  padding: 0;
  

}
.App{
  width: 100%;
  padding: 0;
}
body{
  background-color: #fffae6;
  align-content: center;
  justify-content: center;
}