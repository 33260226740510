/*home page css*/
.bodyView{
   width: auto;
   margin: 0px 180px 0px 180px;
   background-color:rgba(148, 138, 1, 0.076);
}

.homeBody{
    
    align-items: center;
    justify-content: space-around;
    width:auto;
    height: auto;
    background-color: #fffae6;
    min-height: 100px;
overflow: hidden;
}

/*about page css*/
.aboutStyles{
    margin: auto;
    align-items: center;
    width: 100%;
    height: 1100px;
    background-color: #fffae6;
}
.aboutText{
  margin: 0px 180px 0px 180px ;
  background-color:rgba(148, 138, 1, 0.076);

}

/*shedule page css*/
.sheduleList{
  margin: 0px 180px 0px 180px;
  background-color:rgba(148, 138, 1, 0.076);
  padding: 20px;
}
.sheduleList li{
  margin: 10px;
  background-color:rgba(148, 138, 1, 0.076);


}
/*contact page css*/
.contactDetails{
  width: auto;
  margin: 0px 180px 0px 180px;
  height:1100px;
}
.contactInfo{
  display: flex;
}


@media only screen and (max-width: 900px){
  
    .bodyView{
 margin: 0;
    }
    .slides{
      display: block;
      margin: 10px;
    }
    .radioPlayerDiv{
      flex-direction: column;
      margin: auto;
     align-items: center;
     width: 100%;
     text-align: center;
      }
      .abourtOwne{
        flex-direction: column;
      margin: auto;
     align-items: center;
     width: 100%;
      }
      .owerDetails{
        align-items: center;
        text-align: center;
       width: 300px;
      }
      .aboutText{
        margin: 0;
       
      
      }
      .sheduleList{
        margin: 0;
      
    }
    .contactDetails{
    padding: 10px;
      margin: 0;
    }
  }


  