.mainNav{
  width: 100%;
}
.navStyle {
 text-align: center;
 justify-content: center;
 display: flex;
 align-content: center;
}
.navStyle li{
    margin-left: 80px;
    list-style: none;
    padding: 15px;
    }
.navStyle a{
  text-decoration: none;
  color: black;
  font-size: 18px;
  }
  .navStyle li :hover{
    color: #815939;

    }
   
    .drawerMenu{
      display: none;
     }

     @media only screen and (max-width: 900px){
      .navStyle {
       
        display: none;
       
       }
       .drawerMenu{
        display: block;
       text-align: center;
       }
       .menuBarIcon :hover{
        color:#f7e99a ;
       }
       .menuBarBtn{
        background-color: #FFFAE6;
       }
       
    }
  
  