.App{
  width: 100%;
  height: 100%;
}



.each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 380px;
    
  }
  
  .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
    margin-left: 50px;
  }


 

  @media only screen and (max-width: 900px){
    .navStyle {
     
      display: none;
     
     }
     .drawerMenu{
      display: block;
     text-align: center;
     }
     .menuBarIcon :hover{
      color:#f7e99a ;
     }
     .menuBarBtn{
      background-color: #FFFAE6;
     }
     .homeBody{
      width: 900px;
    }
    .bodyView{
 margin: 0;
    }
    .slides{
      display: block;
      margin: 10px;
    }
    .radioPlayerDiv{
      flex-direction: column;
      margin: auto;
     align-items: center;
     width: 100%;
      }
  }

  @media only screen and (max-width: 500px){
   
    
  }